import { ChangeDetectionStrategy, Component, NgZone, OnInit } from '@angular/core';
import { BehaviorSubject, concatMap, filter, from, map, switchMap, tap, } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Storage } from '@shared/services/storage.service';
import { Notification } from '@shared/interfaces/notification.interface';
import { ConferenceCreateInfo } from '@shared/interfaces/conference.interface';
import { jwtDecode } from 'jwt-decode';
import { AuthService } from '@shared/services/auth.service';
import { NotificationComponent } from '@shared/components/notification/notification.component';
import { ApiService } from '@shared/services/api.service';
import { UserInfo, UserInfoResponse } from '@shared/interfaces/users.interface';
import { Router } from '@angular/router';
import { ConferenceHelperService } from './modules/conference/services/conference-helper.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  isAppointmentSendInProgress = false;
  dialogData: any;

  isLoading$: BehaviorSubject<boolean> = this.storage.isLoading$;

  constructor(
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private storage: Storage,
    private authService: AuthService,
    private apiService: ApiService,
    private router: Router,
    private conferenceHelperService: ConferenceHelperService,
  ) { }

  ngOnInit(): void {
    this.initiateApp();
    this.makeAssociations();
 }

 private initiateApp(): void {
   Office.context.mailbox?.item?.loadCustomPropertiesAsync((asyncProp: Office.AsyncResult<Office.CustomProperties>) => {
     this.ngZone.run(() => this.storage.customProperties$.next(asyncProp.value));
   });

   this.listenCustomPropsChanges();
   this.restoreData();
   this.handleNotifications();

   setTimeout(() => {
     if (this.isAppointmentSendInProgress) {
       this.router.navigate(['conference']);
     } else {
       if (this.storage.authToken$.getValue()) {
         this.authService.login(this.storage.MxIp$.getValue(), null, null, this.storage.authToken$.getValue());
       } else {
         from(Office.auth.getAccessToken({
           allowConsentPrompt: true
         })).pipe(
           tap((token: string) => {
             console.log(jwtDecode(token));
             this.storage.authToken$.next(token);
             Office.context.roamingSettings.set('token', token);
             Office.context.roamingSettings.saveAsync();
           }),
           untilDestroyed(this)
         ).subscribe({
           next: (token: string) => this.authService.login(this.storage.MxIp$.getValue(), null, null, token),
           error: () => {
             if (this.storage.sessionId$.getValue()) {
               this.authService.login(this.storage.MxIp$.getValue(), this.storage.login$.getValue());
             } else {
               this.storage.isLoading$.next(false);
             }
           },
         });
       }
     }
   }, 100);

   this.storage.userId$.pipe(
     filter(Boolean),
     switchMap((userId: string) => this.apiService.getUserInfo(userId, this.storage.sessionId$.getValue())),
     map((response: UserInfoResponse) => response.userInfo),
     untilDestroyed(this),
   ).subscribe((userInfo: UserInfo) => this.storage.userName$.next(`${userInfo.firstName} ${userInfo.lastName}`));
 }

 private makeAssociations(): void {
   Office.actions.associate('addConference', () => {
     Office.context.ui.displayDialogAsync(
       `https://${window.location.hostname}/index.html`,
       { height: 45, width: 25, displayInIframe: true },
       (dialogRef) => {
         if (dialogRef.status === Office.AsyncResultStatus.Succeeded) {
           this.storage.isDialogOpened$.next(true);
           this.storage.dialogRef$.next(dialogRef.value);

           dialogRef.value.addEventHandler(Office.EventType.DialogMessageReceived, (event) => {
             // @ts-ignore
             const dialogData = JSON.parse(event.message);
             this.dialogData = {
               ...this.dialogData,
               ...dialogData
             };

             if (this.dialogData.sessionId && this.dialogData.userId && !this.dialogData.conferenceId) {
               this.storage.sessionId$.next(this.dialogData.sessionId);
               this.apiService.getUserInfo(this.dialogData.userId, this.storage.sessionId$.getValue()).pipe(
                 map((response: UserInfoResponse) => response.userInfo),
                 untilDestroyed(this),
               ).subscribe((userInfo: UserInfo) => {
                 this.storage.userName$.next(`${userInfo.firstName} ${userInfo.lastName}`);
                 this.conferenceHelperService.createConference({});
               });
             }

             if (this.dialogData.conferenceId) {
               this.storage.conferenceId$.next(this.dialogData.conferenceId);
               this.conferenceHelperService.setConferenceInfo(this.dialogData, dialogRef);
             }
           });
         }
       }
     );
   });

   Office.actions.associate('onAppointmentSend', (event) => {
     this.isAppointmentSendInProgress = true;

     setTimeout(() => {
       if (!this.storage.conferenceId$.getValue()) {
         event.completed({ allowEvent: true });
       } else {
         this.storage.appointmentSaveEvent$.next(event);
       }
     }, 300);
   });
 }

  private listenCustomPropsChanges(): void {
    this.storage.getSaveQueue().pipe(
      untilDestroyed(this)
    ).subscribe();
  }

  private restoreData(): void {
    this.storage.login$.next(Office.context.roamingSettings.get('login'));
    this.storage.MxIp$.next(Office.context.roamingSettings.get('host'));
    this.storage.loginType$.next(Office.context.roamingSettings.get('loginType'));
    this.storage.authToken$.next(Office.context.roamingSettings.get('token'));
    this.storage.sessionId$.next(Office.context.roamingSettings.get('sessionId'));
    this.storage.trustedDeviceId$.next(Office.context.roamingSettings.get('trustedDeviceId'));
    this.storage.trustedDeviceCode$.next(Office.context.roamingSettings.get('trustedDeviceCode'));

    this.storage.customProperties$.pipe(
      filter(Boolean),
      tap((value: Office.CustomProperties) => {
        const conferenceInfo: ConferenceCreateInfo = JSON.parse(value.get('conferenceInfo') || '{}');

        this.storage.conferenceInfo$.next(conferenceInfo);
        this.storage.conferenceId$.next(value.get('conferenceId'));
        this.storage.lastCreatedConferenceHost$.next(value.get('lastCreatedConferenceHost'));
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private handleNotifications(): void {
    this.storage.notificationsList$.pipe(
      filter(Boolean),
      concatMap((notification: Notification) => this.snackBar.openFromComponent(NotificationComponent, {
        data: { message: notification.message, isWebsocketConnection: notification.isWebsocketConnection || false },
        duration: notification.isWebsocketConnection ? 0 : 3000,
        panelClass: notification.type
      }).afterDismissed()),
      untilDestroyed(this)
    ).subscribe();
  }
}
